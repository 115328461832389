<template>
  <div class="row">
    <div class="form-group col-md-12 text-right">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        data-toggle="modal"
        data-target="#modal_tarifa_crear"
        @click="getDatosModal()"
        v-if="
          $store.getters.can('tep.contratosInternos.edit')
        "
      >
        <i class="fas fa-plus"></i>
      </button>
    </div>

    <table
      class="
        table table-bordered table-striped table-hover
        text-nowrap
        table-sm
      "
      v-if="$parent.form"
    >
      <thead class="thead-dark">
        <tr>
          <th class="text-center">Tarifa</th>
          <th class="text-center">Turno</th>
          <th class="text-center">Horario</th>
          <th class="text-center">Tipo Vehículo</th>
          <th class="text-center">$ Hora Extra</th>
          <th class="text-center">Supervisores</th>
          <th class="text-center">Bolsa Kilómetros</th>
          <th class="text-center">Acum. Km.</th>
          <th class="text-center">$ Km. Adicional</th>
          <th class="text-center">Fecha Ini</th>
          <th class="text-center">Fecha Fin</th>
          <th
            class="text-center"
            style="width: 120px"
          >
            Acción
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tarifa, index) in $parent.form.tarifas" :key="tarifa.id">
          <td class="text-right">${{ tarifa.det_tarifas[0].tarifa }}</td>
          <td>
            {{ tarifa.turno.nombre }}
            {{ index }}
          </td>
          <td>{{ tarifa.nHorario }}</td>
          <td>{{ tarifa.tipo_vehiculo.nombre }}</td>
          <td class="text-right">
            ${{ tarifa.det_tarifas[0].valor_horas_extra }}
          </td>
          <td class="text-center">{{ tarifa.supervisores }}</td>
          <td class="text-right">{{ tarifa.det_tarifas[0].bolsa_km }}</td>
          <td class="text-center">
            <i
              class="fa"
              :class="
                tarifa.det_tarifas[0].acumula_km
                  ? 'fa-check text-success'
                  : 'fa-times-circle text-danger'
              "
            ></i>
          </td>
          <td class="text-right">
            ${{ tarifa.det_tarifas[0].valor_km_adicional }}
          </td>
          <td class="text-right">
            {{ tarifa.det_tarifas[0].inicio_vigencia }}
          </td>
          <td class="text-right">{{ tarifa.det_tarifas[0].fin_vigencia }}</td>
          <td class="text-right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-sm bg-info"
                data-toggle="modal"
                data-target="#modal_tarifa_historico"
                @click="getDatosHistorico(tarifa, index)"
              >
                <i class="fas fa-history"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                data-toggle="modal"
                data-target="#modal_tarifa_actualizar"
                @click="getDatosModal(tarifa)"
                v-if="$store.getters.can('tep.contratosInternos.edit')"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="destroy(tarifa)"
                v-if="$store.getters.can('tep.contratosInternos.edit')"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal de Tarifa crear -->
    <div class="modal fade" id="modal_tarifa_crear">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Crear Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Turno</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.turno_id"
                  :class="$v.form.turno_id.$invalid ? 'is-invalid' : 'is-valid'"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="turno in $parent.listasForms.turnos"
                    :key="turno.id"
                    :value="turno.id"
                  >
                    {{ turno.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Horario</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.horario"
                  :class="$v.form.horario.$invalid ? 'is-invalid' : 'is-valid'"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="horario in $parent.listasForms.horarios"
                    :key="horario.numeracion"
                    :value="horario.numeracion"
                  >
                    {{ horario.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Valor Tarifa</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.tarifa"
                  :class="$v.form.tarifa.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Tipo de Vehículo</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_vehiculo_id"
                  :class="
                    $v.form.tipo_vehiculo_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_vehiculo in $parent.listasForms.tipos_vehiculo"
                    :key="tipo_vehiculo.id"
                    :value="tipo_vehiculo.id"
                  >
                    {{ tipo_vehiculo.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Supervisores</label>
                <select
                  type="number"
                  class="form-control form-control-sm"
                  v-model="form.supervisores"
                  :class="
                    $v.form.supervisores.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.inicio_vigencia"
                  :class="
                    $v.form.inicio_vigencia.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Fin</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fin_vigencia"
                  :class="
                    $v.form.fin_vigencia.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Valor Hora Extra</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.valor_horas_extra"
                  :class="
                    $v.form.valor_horas_extra.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3">
                <label>Bolsa de Kilómetro</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.bolsa_km"
                  :class="$v.form.bolsa_km.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-2 text-center">
                <label for="acumula_km">Acum. Km</label
                ><input
                  type="checkbox"
                  id="acumula_km"
                  class="form-control form-control-sm p-0"
                  v-model="form.acumula_km"
                />
              </div>
              <div class="form-group col-md-4">
                <label>Valor Kilómetro Adicional</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.valor_km_adicional"
                  :class="
                    $v.form.valor_km_adicional.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tep.contratosInternos.edit') &&
                !$v.form.$invalid
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Modal de Tarifa actualizar -->
    <div class="modal fade" id="modal_tarifa_actualizar">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Actualizar Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Turno</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form1.turno_id"
                  :class="
                    $v.form1.turno_id.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="turno in $parent.listasForms.turnos"
                    :key="turno.id"
                    :value="turno.id"
                  >
                    {{ turno.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Horario</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form1.horario"
                  :class="$v.form1.horario.$invalid ? 'is-invalid' : 'is-valid'"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="horario in $parent.listasForms.horarios"
                    :key="horario.numeracion"
                    :value="horario.numeracion"
                  >
                    {{ horario.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Valor Tarifa</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form1.tarifa"
                  :class="$v.form1.tarifa.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Tipo de Vehículo</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form1.tipo_vehiculo_id"
                  :class="
                    $v.form1.tipo_vehiculo_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_vehiculo in $parent.listasForms.tipos_vehiculo"
                    :key="tipo_vehiculo.id"
                    :value="tipo_vehiculo.id"
                  >
                    {{ tipo_vehiculo.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Supervisores</label>
                <select
                  type="number"
                  class="form-control form-control-sm"
                  v-model="form1.supervisores"
                  :class="
                    $v.form1.supervisores.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Valor Hora Extra</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form1.valor_horas_extra"
                  :class="
                    $v.form1.valor_horas_extra.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3">
                <label>Bolsa de Kilómetro</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form1.bolsa_km"
                  :class="
                    $v.form1.bolsa_km.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-2 text-center">
                <label for="acumula_km">Acum. Km</label
                ><input
                  type="checkbox"
                  id="acumula_km"
                  class="form-control form-control-sm p-0"
                  v-model="form1.acumula_km"
                />
              </div>
              <div class="form-group col-md-4">
                <label>Valor Kilómetro Adicional</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form1.valor_km_adicional"
                  :class="
                    $v.form1.valor_km_adicional.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tep.contratosInternos.edit') &&
                !$v.form1.$invalid
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Modal de Histórico -->
    <div class="modal fade" id="modal_tarifa_historico">
      <div class="modal-dialog modal-xl" v-if="datos_tarifa_historico">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Histórico Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal2"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="position-relative p-3 m-2 bg-light"
              style="font-size: 0.85em"
            >
              <div class="row">
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.tarifa }}
                    </h5>
                    <span class="description-text text-secondary">Tarifa</span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.turno }}
                    </h5>
                    <span class="description-text text-secondary">Turno</span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.horario }}
                    </h5>
                    <span class="description-text text-secondary">Horario</span>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.tipo_vehiculo }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Tipo Vehículo</span
                    >
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.valor_horas_extra }}
                    </h5>
                    <span class="description-text text-secondary"
                      >$ Hora Extra</span
                    >
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.supervisores }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Supervisores</span
                    >
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5 class="description-header">
                      <i
                        class="fa"
                        :class="
                          datos_tarifa_historico.acumula_km
                            ? 'fa-check text-success'
                            : 'fa-times-circle text-danger'
                        "
                      ></i>
                    </h5>
                    <span class="description-text text-secondary"
                      >Acum. Km.</span
                    >
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.valor_km_adicional }}
                    </h5>
                    <span class="description-text text-secondary"
                      >$ Kilómetro Adicional</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.inicio_vigencia }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Fecha Inicial</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.fin_vigencia }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Fecha Final</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <h5>Actualizar Vigencia</h5>
            <div class="row">
              <div class="form-group col-md-3">
                <label>Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form2.inicio_vigencia"
                  :class="
                    $v.form2.inicio_vigencia.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Fin</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form2.fin_vigencia"
                  :class="
                    $v.form2.fin_vigencia.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-2 pt-4 text-right">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#modal_tarifa"
                  class="btn btn-primary btn-sm"
                  @click="save()"
                  v-if="
                    $store.getters.can('tep.contratosInternos.edit') &&
                    !$v.form2.$invalid
                  "
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body table-responsive">
            <table
              class="
                table table-bordered table-striped table-hover
                text-nowrap
                table-sm
              "
            >
              <thead class="thead-dark">
                <tr>
                  <th class="text-center" style="width: 120px">Estado</th>
                  <th class="text-center">Tarifa</th>
                  <th class="text-center">$ Hora Extra</th>
                  <th class="text-center">Bolsa Kilómetros</th>
                  <th class="text-center">Acum. Km.</th>
                  <th class="text-center">$ Km. Adicional</th>
                  <th class="text-center">Fecha Ini</th>
                  <th class="text-center">Fecha Fin</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tarifa in tarifa_historico" :key="tarifa.id">
                  <td class="text-center">
                    <span
                      class="badge"
                      :class="
                        tarifa.estado == 1 ? 'badge-success' : 'badge-danger'
                      "
                      >{{ tarifa.nEstado }}</span
                    >
                  </td>
                  <td class="text-right">${{ tarifa.tarifa }}</td>
                  <td class="text-right">${{ tarifa.valor_horas_extra }}</td>
                  <td class="text-center">{{ tarifa.bolsa_km }}</td>
                  <td class="text-center">
                    <i
                      class="fa"
                      :class="
                        tarifa.acumula_km
                          ? 'fa-check text-success'
                          : 'fa-times-circle text-danger'
                      "
                    ></i>
                  </td>
                  <td class="text-right">${{ tarifa.valor_km_adicional }}</td>
                  <td class="text-right">{{ tarifa.inicio_vigencia }}</td>
                  <td class="text-right">{{ tarifa.fin_vigencia }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TepTarifaInterno",
  components: {},
  data() {
    return {
      cargando: false,
      tarifa_index: null,
      // Formulario de cracion de tarifa
      form: {
        id: null,
        tep_contrato_interno_id: this.$parent.id,
        tarifa: null,
        turno_id: null,
        horario: null,
        tipo_vehiculo_id: null,
        valor_horas_extra: null,
        supervisores: null,
        bolsa_km: null,
        valor_km_adicional: null,
        acumula_km: true,
        inicio_vigencia: null,
        fin_vigencia: null,
      },
      // Formulario de edición de datos de la tarifa vigente
      form1: {
        id: null,
        tep_contrato_interno_id: this.$parent.id,
        tep_det_tarifa_interno_id: null,
        tarifa: null,
        turno_id: null,
        horario: null,
        tipo_vehiculo_id: null,
        valor_horas_extra: null,
        supervisores: null,
        bolsa_km: null,
        valor_km_adicional: null,
        acumula_km: true,
      },
      // Formulario para actualización de fechas de la tarifa
      form2: {
        id: null,
        tep_contrato_interno_id: this.$parent.id,
        tarifa: null,
        turno_id: null,
        horario: null,
        tipo_vehiculo_id: null,
        valor_horas_extra: null,
        supervisores: null,
        bolsa_km: null,
        valor_km_adicional: null,
        acumula_km: true,
        inicio_vigencia: null,
        fin_vigencia: null,
      },
      listasForms: {
        sitios: [],
        estados: {},
      },
      datos_tarifa_historico: [],
      tarifa_historico: [],
    };
  },
  validations() {
    return {
      form: {
        tarifa: {
          required,
        },
        turno_id: {
          required,
        },
        horario: {
          required,
        },
        tipo_vehiculo_id: {
          required,
        },
        valor_horas_extra: {
          required,
        },
        supervisores: {
          required,
        },
        bolsa_km: {
          required,
        },
        valor_km_adicional: {
          required,
        },
        inicio_vigencia: {
          required,
        },
        fin_vigencia: {
          required,
        },
      },
      form1: {
        tarifa: {
          required,
        },
        turno_id: {
          required,
        },
        horario: {
          required,
        },
        tipo_vehiculo_id: {
          required,
        },
        valor_horas_extra: {
          required,
        },
        supervisores: {
          required,
        },
        bolsa_km: {
          required,
        },
        valor_km_adicional: {
          required,
        },
      },
      form2: {
        inicio_vigencia: {
          required,
        },
        fin_vigencia: {
          required,
        },
      },
    };
  },
  methods: {
    getDatosModal(tarifa = null) {
      this.$parent.cargando = true;
      this.resetForm();
      if (tarifa) {
        const det_tarifa = tarifa.det_tarifas[0];
        this.form1 = {
          id: tarifa.id,
          tep_contrato_interno_id: tarifa.tep_contrato_interno_id,
          tep_det_tarifa_interno_id: det_tarifa.id,
          tarifa: det_tarifa.tarifa,
          turno_id: tarifa.turno_id,
          horario: tarifa.horario,
          tipo_vehiculo_id: tarifa.tipo_vehiculo_id,
          valor_horas_extra: det_tarifa.valor_horas_extra,
          supervisores: tarifa.supervisores,
          bolsa_km: det_tarifa.bolsa_km,
          valor_km_adicional: det_tarifa.valor_km_adicional,
          acumula_km: det_tarifa.acumula_km,
        };
      }
      this.$parent.cargando = false;
    },

    getDatosHistorico(tarifa, index) {
      this.resetForm();
      this.tarifa_index = index;
      const det_tarifa = tarifa.det_tarifas[0];
      this.form2 = {
        id: tarifa.id,
        tep_contrato_interno_id: tarifa.tep_contrato_interno_id,
        tep_det_tarifa_interno_id: det_tarifa.id,
        tarifa: det_tarifa.tarifa,
        turno_id: tarifa.turno_id,
        horario: tarifa.horario,
        tipo_vehiculo_id: tarifa.tipo_vehiculo_id,
        valor_horas_extra: det_tarifa.valor_horas_extra,
        supervisores: tarifa.supervisores,
        bolsa_km: det_tarifa.bolsa_km,
        valor_km_adicional: det_tarifa.valor_km_adicional,
        acumula_km: det_tarifa.acumula_km,
      };
      this.datos_tarifa_historico = {
        tarifa: tarifa.det_tarifas[0].tarifa,
        turno: tarifa.turno.nombre,
        horario: tarifa.horario,
        tipo_vehiculo: tarifa.tipo_vehiculo.nombre,
        valor_horas_extra: tarifa.det_tarifas[0].valor_horas_extra,
        supervisores: tarifa.supervisores,
        bolsa_km: tarifa.det_tarifas[0].bolsa_km,
        acumula_km: tarifa.det_tarifas[0].acumula_km,
        valor_km_adicional: tarifa.det_tarifas[0].valor_km_adicional,
        inicio_vigencia: tarifa.det_tarifas[0].inicio_vigencia,
        fin_vigencia: tarifa.det_tarifas[0].fin_vigencia,
      };
      this.tarifa_historico = tarifa.det_tarifas;
    },

    resetForm() {
      this.tarifa_index = null;
      this.form = {
        id: null,
        tep_contrato_interno_id: this.$parent.id,
        tarifa: null,
        turno_id: null,
        horario: null,
        tipo_vehiculo_id: null,
        valor_horas_extra: null,
        supervisores: null,
        bolsa_km: null,
        valor_km_adicional: null,
        acumula_km: false,
        inicio_vigencia: null,
        fin_vigencia: null,
      };
      this.form1 = {
        id: null,
        tep_contrato_interno_id: this.$parent.id,
        tep_det_tarifa_interno_id: null,
        tarifa: null,
        turno_id: null,
        horario: null,
        tipo_vehiculo_id: null,
        valor_horas_extra: null,
        supervisores: null,
        bolsa_km: null,
        valor_km_adicional: null,
        acumula_km: false,
      };
      this.form2 = {
        id: null,
        tep_contrato_interno_id: this.$parent.id,
        tarifa: null,
        turno_id: null,
        horario: null,
        tipo_vehiculo_id: null,
        valor_horas_extra: null,
        supervisores: null,
        bolsa_km: null,
        valor_km_adicional: null,
        acumula_km: false,
        inicio_vigencia: null,
        fin_vigencia: null,
      };
    },

    async validarFechas() {
      // Se valida que las fechas estén dentro de la vigencia del contrato
      await this.validaVigenciaContrato();
      // Se valida que la fecha inicial no sea mayor a la fecha final
      await this.validaFechasVigencia();
      // Si ya la tarifa ha sido creada se valida que la fecha inicial a crear so sea menor de la fecha final de la tarifa creada vigente
      if (this.form.id || this.form2.id) {
        await this.validaFechasTarifa();
      }
    },

    validaVigenciaContrato() {
      const inicio_contrato = this.$parent.form.fecha_ini;
      const fin_contrato = this.$parent.form.fecha_fin;
      // Validaciones del formulario de crear
      if (this.form.inicio_vigencia) {
        if (
          !this.validaFechas(inicio_contrato, this.form.inicio_vigencia) ||
          !this.validaFechas(this.form.inicio_vigencia, fin_contrato)
        ) {
          this.form.inicio_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fin_vigencia) {
        if (
          !this.validaFechas(inicio_contrato, this.form.fin_vigencia) ||
          !this.validaFechas(this.form.fin_vigencia, fin_contrato)
        ) {
          this.form.fin_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Fin de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      // Validaciones del formulario cambiar vigencia
      if (this.form2.inicio_vigencia) {
        if (
          !this.validaFechas(inicio_contrato, this.form2.inicio_vigencia) ||
          !this.validaFechas(this.form2.inicio_vigencia, fin_contrato)
        ) {
          this.form2.inicio_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form2.fin_vigencia) {
        if (
          !this.validaFechas(inicio_contrato, this.form2.fin_vigencia) ||
          !this.validaFechas(this.form2.fin_vigencia, fin_contrato)
        ) {
          this.form2.fin_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Fin de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasVigencia() {
      // Validaciones del formulario de crear
      if (this.form.inicio_vigencia && this.form.fin_vigencia) {
        if (
          !this.validaFechas(this.form.inicio_vigencia, this.form.fin_vigencia)
        ) {
          this.form.fin_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa no puede ser mayor a la Fecha Fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      // Validaciones del formulario cambiar vigencia
      if (this.form2.inicio_vigencia && this.form2.fin_vigencia) {
        if (
          !this.validaFechas(
            this.form2.inicio_vigencia,
            this.form2.fin_vigencia
          )
        ) {
          this.form2.fin_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa no puede ser mayor a la Fecha Fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasTarifa() {
      // Validaciones del formulario de crear
      if (this.form.inicio_vigencia) {
        const fecha_fin_det_tarifa =
          this.$parent.form.tarifas[0].det_tarifas[0].fin_vigencia;
        if (
          !this.validaFechas(fecha_fin_det_tarifa, this.form.inicio_vigencia)
        ) {
          this.form.inicio_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha de Inicio se cruza con la vigencia de la tarifa actual, favor poner una fecha posterior a ${fecha_fin_det_tarifa}.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }
      // Validaciones del formulario cambiar vigencia
      if (this.form2.inicio_vigencia) {
        const fecha_fin_det_tarifa =
          this.$parent.form.tarifas[this.tarifa_index].det_tarifas[0]
            .fin_vigencia;
        if (
          !this.validaFechas(fecha_fin_det_tarifa, this.form2.inicio_vigencia)
        ) {
          this.form2.inicio_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha de Inicio se cruza con la vigencia de la tarifa actual, favor poner una fecha posterior a ${fecha_fin_det_tarifa}.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechas(fecha_menor, fecha_mayor) {
      fecha_menor = new Date(fecha_menor);
      fecha_mayor = new Date(fecha_mayor);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        return false;
      }
      return true;
    },

    save() {
      this.$parent.cargando = true;

      let form = null;
      let method = null;
      if (!this.$v.form.$invalid) {
        method = `POST`;
        form = this.form;
      }
      if (!this.$v.form1.$invalid) {
        method = `PUT`;
        form = this.form1;
      }
      if (!this.$v.form2.$invalid) {
        method = `POST`;
        form = this.form2;
      }

      if (form) {
        axios({
          method,
          url: "/api/tep/contratosInternos/tarifas",
          data: form,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$parent.form.tarifas = response.data.tarifas;
            this.$refs.closeModal.click();
            this.$refs.closeModal1.click();
            this.$refs.closeModal2.click();

            this.$swal({
              icon: "success",
              title: "La tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.resetForm();
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(tarifa) {
      this.$swal({
        title: "Está seguro de eliminar esta Tarifa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .delete("/api/tep/contratosInternos/tarifas/" + tarifa.id)
            .then((response) => {
              this.$parent.cargando = false;
              this.$parent.form.tarifas = response.data.tarifas;
              this.$swal({
                icon: "success",
                title: "Se eliminó la Tarifa exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
};
</script>
